import React from 'react';
import loadable from '@loadable/component';
import { graphql } from 'gatsby';
import Reveal from '@/components/reveal/Reveal';
import HomeStyled from '@/components/styled/HomeStyled';
import { HomePageProps } from '@/types';
import { usePageMeta } from '@/components/app/hooks';

const HeroSection = loadable(() => import(`@/app/hero/HeroSection`));
const CaseSection = loadable(() => import(`@/app/case/CaseSection`));
const JourneySection = loadable(() => import(`@/app/journey/JourneySection`));
const ServicesSection = loadable(
  () => import(`@/app/services/ServicesSection`),
);
const BlogSection = loadable(() => import(`@/app/blog/BlogSection`));
const ClientsSection = loadable(() => import(`@/app/clients/ClientsSection`));

const Index: React.FC<HomePageProps> = ({ data }) => (
  <HomeStyled>
    {usePageMeta(data.contentfulBlock?.meta)}
    <div className="content home-page">
      <HeroSection />

      <Reveal className="introduction container">
        <p>
          IONA is an award-winning ecommerce agency that helps brands sell more
          online with next-generation strategies, customer experiences and
          composable commerce solutions
        </p>
      </Reveal>

      <CaseSection />
      <JourneySection />
      <ServicesSection />
      <ClientsSection />
      <BlogSection />
    </div>
  </HomeStyled>
);

export const query = graphql`
  query homeBlock {
    contentfulBlock(slug: { eq: "home-heading" }, pages: { eq: "home" }) {
      meta {
        title
        description
        type
        image {
          file {
            url
          }
        }
      }
    }
  }
`;

export default Index;
